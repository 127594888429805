import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import ArticleCard from '../components/articleCard';
import Pagination from '../components/pagination';

const IndexPage = () => {
	const data = useStaticQuery(graphql`
		query indexQuery {
            postCount: allWordpressPost {
                totalCount
            }
			posts: allWordpressPost(
                limit: 10
                skip: 0
                sort: {fields: date, order: DESC}
            ) {

                nodes {
                    id
                    date
                    title
                    slug
                    excerpt
                    author {
                        name
                        acf {
                            avatar_image {
                                localFile {
                                    childImageSharp {
                                        sizes(maxWidth: 100 ) {
                                            ...GatsbyImageSharpSizes
                                        }
                                        fluid(maxWidth: 100, maxHeight: 100) {
                                            ...GatsbyImageSharpFluid_withWebp
                                        }
                                    }
                                }
                            }
                        }
                    }
                    date
                    categories {
                        name
                        slug
                        id
                        wordpress_parent
                        parent_element {
                            name
                            slug
                        }
                    }
                    link
                    featured_media {
                        localFile {
                            childImageSharp {
                                sizes(maxWidth: 2064 ) {
                                    ...GatsbyImageSharpSizes
                                }
                                fluid {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                    acf {
                        sub_headline
                    }
                } 
            }
            site {
                siteMetadata {
                    title
                }
            }
            primaryCategories: allWordpressCategory(filter: {wordpress_parent: {eq: 0}, wordpress_id: {ne: 1}}, sort: {fields: name}) {
                edges {
                    node {
                        count
                        id
                        slug
                        name
                        wordpress_parent
                        wordpress_id
                        parent_element {
                            slug
                            name
                        }
                    }
                }
            }
            secondaryCategories: allWordpressCategory(filter: {wordpress_parent: {ne: 0}}, sort: {fields: name}) {
                edges {
                    node {
                        count
                        id
                        slug
                        name
                        wordpress_parent
                        wordpress_id
                        parent_element {
                            slug
                            name 
                        }
                    }
                }
            }
		}
    `);
    
    const posts = data.posts.nodes;
    const postCount = data.postCount.totalCount;

    let parentCat = {
        wordpress_id: 0
    };

    let subCat = {
        wordpress_id: 0
    };

    const pageContext = {
        currentPage: 1,
        numPages: Math.ceil(postCount / 10),
    };

	return (
		<Layout data={data} category={parentCat} subCategory={subCat}>
            <SEO title="Home" />
            {
                posts.map(post => {
                    let parentCat = null;
                    let subCat = null;
            
                    post.categories.forEach(cat => {
                        if( cat.parent_element ){
                            parentCat = cat.parent_element;
                            subCat = {
                                slug: cat.slug,
                                name: cat.name,
                                wordpress_id: cat.wordpress_id
                            };
                        } else {}
                    });

                    return (
                        <ArticleCard key={post.id} id={post.id} link={"/" + parentCat.slug + "/" + subCat.slug + "/" + post.slug} title={post.title} media={post.featured_media} excerpt={post.excerpt} subheadline={post.acf.sub_headline} author={post.author} date={post.date} parentCategory={parentCat} subCategory={subCat} />
                    );
                })
            }
            <Pagination pageContext={pageContext} catSlug="blog" category={parentCat} subCategory={subCat} />
        </Layout>
	);
};

export default IndexPage;